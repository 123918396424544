import type { ReactNode } from "react";
import { Component } from "react";

// File inspired and adapted from https://github.com/gatsbyjs/gatsby/issues/33956#issuecomment-977854027

interface Props {
	children: ReactNode;
}

interface State {
	error: Error | null;
}

const INITIAL_STATE: State = {
	error: null,
};

export class CoreErrorBoundary extends Component<Props, State> {
	public state = INITIAL_STATE;

	public componentDidCatch(error: Error): void {
		if (
			// https://github.com/gatsbyjs/gatsby/issues/33844
			error.name === "ChunkLoadError" ||
			// https://github.com/gatsbyjs/gatsby/issues/33956
			error.message.startsWith(
				"The result of this StaticQuery could not be fetched",
			)
		) {
			this.setState({ error });

			// Prevent an infinite loop by limiting to a single reload
			const currentUrl = new URL(window.location.href);
			const paramName = "reloaded";
			if (currentUrl.searchParams.get(paramName) === "1") {
				// Already reloaded, so propagate the error to the next error boundary
				throw error;
			} else {
				currentUrl.searchParams.set(paramName, "1");
				window.location.href = currentUrl.href;
			}
		} else {
			// Propagate any other error to the next error boundary
			throw error;
		}
	}

	public render(): ReactNode {
		return this.state.error ? null : this.props.children;
	}
}
