import { countries, type TCountryCode } from "countries-list";
import { isLighthouse } from "./browser-utils";
import { getCookie, removeCookie, setCookie } from "./cookies/cookie-service";
import { getQueryStringParam } from "./query-string";

export const isValidCountryCode = (value: unknown): value is TCountryCode =>
	Object.keys(countries).includes(value as TCountryCode);

export const setCountryCookie = (country: TCountryCode) => {
	setCookie({
		name: "country",
		value: country,
		attributes: { expires: 365, path: "/", sameSite: "lax" },
		requireConsent: "strict",
	});
};

export const getUserCountry = (
	allowedCountries: string[],
): TCountryCode | undefined => {
	if (
		process.env.GATSBY_LOCALE_REDIRECT_POPUP_ACTIVE === "false" ||
		isLighthouse()
	) {
		return isValidCountryCode(allowedCountries[0])
			? allowedCountries[0]
			: undefined;
	}

	const countrySearch = getQueryStringParam("country")
		?.toString()
		.toUpperCase();

	if (
		countrySearch &&
		allowedCountries.includes(countrySearch) &&
		isValidCountryCode(countrySearch)
	) {
		console.log("site country from countrySearch", countrySearch);
		return countrySearch;
	}

	const countryCookie = getCookie("country")?.toUpperCase();

	if (
		countryCookie &&
		allowedCountries.includes(countryCookie) &&
		isValidCountryCode(countryCookie)
	) {
		console.log("site country from cookie", countryCookie);
		return countryCookie;
	} else if (countryCookie) {
		console.log("invalid country in cookie", countryCookie);
		removeCookie("country");
	}
};
