import React from "react";
import ContextComponents from "../../context/contexts";

const ContextWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
	const TopLevelComponent: React.FC<React.PropsWithChildren> = ({
		children,
	}) => <React.Fragment>{children}</React.Fragment>;

	const NestedContexts = ContextComponents.reduce(
		(WrappingComponent, CurrentComponent) =>
			function NestedContext(props: React.PropsWithChildren) {
				return (
					<WrappingComponent>
						<CurrentComponent {...props} />
					</WrappingComponent>
				);
			},
		TopLevelComponent,
	);

	return <NestedContexts>{children}</NestedContexts>;
};

export default ContextWrapper;
