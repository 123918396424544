import { isLighthouse } from "../browser-utils";
import { getCookie, hasCookie } from "./cookie-service";

export type CookieConsent =
	| "strict"
	| "performance"
	| "targeting"
	| "functionality"
	| "unclassified";

export type ConsentCookie = {
	googleconsentmap: {
		ad_storage: string;
		analytics_storage: string;
		personalization_storage: string;
		functionality_storage: string;
		security_storage: string;
	};
	bannershown: 1 | 0;
	action: "accept" | "reject";
	categories: CookieConsent[];
};

export const consentCookieName = "CookieScriptConsent";

export const bypassConsent = (): boolean =>
	isLighthouse() || process.env.GATSBY_IS_PREVIEW === "true";

const fullConsent = (): ConsentCookie => ({
	googleconsentmap: {
		ad_storage: "targeting",
		analytics_storage: "performance",
		personalization_storage: "functionality",
		functionality_storage: "functionality",
		security_storage: "functionality",
	},
	bannershown: 1,
	action: "accept",
	categories: ["performance", "targeting", "functionality", "unclassified"],
});

const rejectedConsent = (): ConsentCookie => ({
	googleconsentmap: {
		ad_storage: "targeting",
		analytics_storage: "performance",
		personalization_storage: "functionality",
		functionality_storage: "functionality",
		security_storage: "functionality",
	},
	bannershown: 1,
	action: "reject",
	categories: [],
});

export const getCookieConsent = (): ConsentCookie => {
	if (bypassConsent()) {
		return fullConsent();
	}

	const cookie = getCookie(consentCookieName);
	if (!cookie) {
		return rejectedConsent();
	}

	const consent = JSON.parse(cookie) as ConsentCookie;
	if (consent.categories && consent.action && consent.googleconsentmap) {
		return consent;
	}

	return rejectedConsent();
};

/**
 * Checks if a specific cookie type has consent to be used.
 * This function is used by Google Tag Manager to check if a specific cookie type is valid which will allow/disallow tags.
 *
 * @param type CookieTypeKey
 * @returns boolean
 */
export const hasCookieConsent = (category: CookieConsent): boolean => {
	if (category === "strict") return true;
	const consentCookie = getCookieConsent();
	const hasConsentCategory = consentCookie.categories.includes(category);
	return (
		consentCookie !== undefined &&
		consentCookie.action === "accept" &&
		hasConsentCategory
	);
};

export const hasConsentCookie = (): boolean =>
	isLighthouse() || hasCookie(consentCookieName);
